<template>
  <div class="transit-block">
    <div class="business-trip__expenses-row">
      <div class="business-trip__expenses-col">
        <div class="transport-type">
          <div
            class="transport-type__item"
            :class="{
              'transport-type__item--active':
                fields.transitWay === plane,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: plane,
              })
            "
          >
            <esmp-icon name="bt__plane" />
          </div>
          <div
            class="transport-type__item"
            :class="{
              'transport-type__item--active':
                fields.transitWay === train,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: train,
              })
            "
          >
            <esmp-icon name="bt__train" />
          </div>
          <!--
          <div
            class="transport-type__item"
            :class="{
              'transport-type__item--active': fields.transitWay === bus,
            }"
            role="button"
            @click="
              update({
                field: 'transitWay',
                value: bus,
              })
            "
          >
            <esmp-icon name="bt__bus" />
          </div>
          -->
        </div>
      </div>
      <div class="business-trip__expenses-col" v-show="false">
        <div class="business-trip__expenses-fieldset ">
          <div
            class="business-trip__expenses-field business-trip__expenses-field--big"
          >
            <esmp-select
              placeholder="Территориальная зона"
              filterable
              clearable
              :value="fields.zone ? fields.zone.id : null"
              @input="
                update({
                  field: 'zone',
                  value: $event,
                  list: territorialZones,
                })
              "
            >
              <esmp-select-option
                v-for="item in territorialZones"
                :key="item.id"
                :value="item.id"
              >
                {{ item.name }}
              </esmp-select-option>
            </esmp-select>
          </div>
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="way-block">
          <div class="business-trip__expenses-fieldset">
            <div class="business-trip__expenses-field">
              <validation-provider
                :name="`Откуда ${(currentIndex + 1)}`"
                rules="required|city"
                v-slot="{ errors }"
              >
                <destination-field
                  :key="`transitStart_${currentIndex}`"
                  placeholder="Откуда"
                  :is-valid="true"
                  :value="fields.startPoint"
                  @input="
                    update({
                      field: 'startPoint',
                      value: $event,
                    })
                  "
                />
                <div v-if="errors[0]" class="business-trip__error">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
            <div class="business-trip__expenses-field">
              <validation-provider
                :name="`Куда ${(currentIndex + 1)}`"
                rules="required|city"
                v-slot="{ errors }"
              >
                <destination-field
                  :key="`endPoint_${currentIndex}`"
                  placeholder="Куда"
                  :is-valid="true"
                  :value="fields.endPoint"
                  @change="setZone"
                  @input="
                    update({
                      field: 'endPoint',
                      value: $event,
                    })"
                />
                <div v-if="errors[0]" class="business-trip__error">
                  {{ errors[0] }}
                </div>
              </validation-provider>
            </div>
          </div>
          <div
            class="way-block__switcher"
            role="button"
            @click="switchFields('startPoint', 'endPoint')"
          >
            <esmp-icon name="24-arrow-right-left" />
          </div>
        </div>
      </div>

      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <div class="business-trip__expenses-field">
            <validation-provider
              :name="`Когда ${(currentIndex + 1)}`"
              rules="required"
              v-slot="{ errors }"
            >
              <esmp-datepicker-adaptive
                placeholder="Когда"
                @change="
                  update({
                    field: 'day',
                    value: $event,
                  })
                "
                :value="fields.day"
              />
              <div v-if="errors[0]" class="business-trip__error">
                {{ errors[0] }}
              </div>
            </validation-provider>
          </div>
        </div>
      </div>

      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === plane"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер рейса"
            :value="fields.planeTripNumber"
            @input="
              update({
                field: 'planeTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === train"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер поезда"
            :value="fields.trainTripNumber"
            @input="
              update({
                field: 'trainTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="fields.transitWay === bus"
      >
        <div class="business-trip__expenses-fieldset">
          <esmp-input
            error-message=""
            label="Номер рейса"
            :value="fields.busTripNumber"
            @input="
              update({
                field: 'busTripNumber',
                value: $event,
              })
            "
          />
        </div>
      </div>
      <div class="business-trip__expenses-col">
        <div class="business-trip__expenses-fieldset">
          <esmp-select
            placeholder="Отправление"
            filterable
            clearable
            :disabled="isHasTripNumber"
            :value="fields.timeOfDay ? fields.timeOfDay.id : null"
            @input="
              update({
                field: 'timeOfDay',
                value: $event,
                list: timeOfDay,
              })
            "
          >
            <esmp-select-option
              v-for="item in timeOfDay"
              :key="item.id"
              :value="item.id"
            >
              {{ item.name }}
            </esmp-select-option>
          </esmp-select>
        </div>
      </div>
      <div
        class="business-trip__expenses-col"
        v-if="isVip && fields.transitWay === plane"
      >
        <esmp-checkbox
          :value="fields.isBusinessClass"
          @input="
            update({
              field: 'isBusinessClass',
              value: $event,
            })
          "
        >
          Бизнес-класс
        </esmp-checkbox>
      </div>

      <div
        class="business-trip__expenses-col"
        v-if="isVip && fields.transitWay === train"
      >
        <esmp-checkbox
          :value="fields.isFirstClass"
          @input="
            update({
              field: 'isFirstClass',
              value: $event,
            })
          "
        >
          Купе 1 класс
        </esmp-checkbox>
      </div>

      <div class="business-trip__expenses-col" v-if="isAvailableRemove">
        <action-btn
          :is-remove="true"
          @click="removeTransit"
        >
          Удалить маршрут
        </action-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';

import { BUSINESS_TRIP_TRANSIT_WAYS } from '@/constants/business-trip2';

import DestinationField from '../components/destination/DestinationField.vue';
import ActionBtn from '../components/ActionBtn.vue';
import getDestinationCity from '../helpers/getDestinationCity';

export default {
  name: 'TransitBlock',
  components: {
    ActionBtn,
    DestinationField,
  },
  props: {
    fields: {
      type: Object,
      default: undefined,
    },
    isVip: Boolean,
    currentIndex: {
      type: [Number, String],
      default: undefined,
    },
    isAvailableRemove: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      plane: BUSINESS_TRIP_TRANSIT_WAYS.plane,
      train: BUSINESS_TRIP_TRANSIT_WAYS.train,
      bus: BUSINESS_TRIP_TRANSIT_WAYS.bus,
      isSwitch: false,
    };
  },
  computed: {
    ...mapGetters('businessTrip2', ['territorialZones', 'timeOfDay']),
    isHasTripNumber() {
      const hasNumber = !isEmpty(
        this.fields[`${this.fields.transitWay}TripNumber`],
      );

      if (hasNumber) {
        this.update({
          field: 'timeOfDay',
          value: null,
        });
      }

      return hasNumber;
    },
  },
  methods: {
    ...mapActions('businessTrip2', ['updateTransit', 'removeTransitBlock']),
    update({ field, value, list }) {
      let val = value;
      if (list && list.length) {
        val = list.find((i) => i.id === value);
      }
      this.updateTransit({
        index: this.currentIndex,
        field,
        value: val,
      });
    },
    removeTransit() {
      this.removeTransitBlock(Number(this.currentIndex));
    },
    switchFields(firstField, secondField) {
      if (!this.isSwitch) {
        this.isSwitch = true;

        const thirdGlass = cloneDeep(this.fields[firstField]);
        this.update({
          field: firstField,
          value: cloneDeep(this.fields[secondField]),
        });
        this.update({
          field: secondField,
          value: thirdGlass,
        });

        this.isSwitch = false;
      }
    },
    setZone() {
      if (!this.fields.endPoint) return;
      let zone = null;
      const r = getDestinationCity(this.fields.endPoint);
      if (r) {
        zone = this.territorialZones.find(
          (item) => item.id === r.zoneCode || null,
        );
      }

      this.update({
        field: 'zone',
        value: zone,
      });
    },
  },
};
</script>

<style scoped lang="scss">
.transport-type {
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  &__item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    background: $esmp-input-background-color;
    color: $color-black-op-40;

    &:not(:last-child) {
      margin-right: 3px;
    }

    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
    &--active {
      color: $color-primary-1-day;
    }
  }
}

.way-block {
  position: relative;
  &__switcher {
    position: absolute;
    top: 50%;
    left: calc(50% - 80px);
    z-index: 6;
    width: 18px;
    height: 14px;
    background-repeat: no-repeat;
    background-position: center;
    transform: translateY(-50%);

    &:hover {
      cursor: pointer;
    }
  }
}

</style>
