var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transit-block"},[_c('div',{staticClass:"business-trip__expenses-row"},[_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"transport-type"},[_c('div',{staticClass:"transport-type__item",class:{
            'transport-type__item--active':
              _vm.fields.transitWay === _vm.plane,
          },attrs:{"role":"button"},on:{"click":function($event){return _vm.update({
              field: 'transitWay',
              value: _vm.plane,
            })}}},[_c('esmp-icon',{attrs:{"name":"bt__plane"}})],1),_c('div',{staticClass:"transport-type__item",class:{
            'transport-type__item--active':
              _vm.fields.transitWay === _vm.train,
          },attrs:{"role":"button"},on:{"click":function($event){return _vm.update({
              field: 'transitWay',
              value: _vm.train,
            })}}},[_c('esmp-icon',{attrs:{"name":"bt__train"}})],1)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field business-trip__expenses-field--big"},[_c('esmp-select',{attrs:{"placeholder":"Территориальная зона","filterable":"","clearable":"","value":_vm.fields.zone ? _vm.fields.zone.id : null},on:{"input":function($event){return _vm.update({
                field: 'zone',
                value: $event,
                list: _vm.territorialZones,
              })}}},_vm._l((_vm.territorialZones),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"way-block"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":`Откуда ${(_vm.currentIndex + 1)}`,"rules":"required|city"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('destination-field',{key:`transitStart_${_vm.currentIndex}`,attrs:{"placeholder":"Откуда","is-valid":true,"value":_vm.fields.startPoint},on:{"input":function($event){return _vm.update({
                    field: 'startPoint',
                    value: $event,
                  })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1),_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":`Куда ${(_vm.currentIndex + 1)}`,"rules":"required|city"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('destination-field',{key:`endPoint_${_vm.currentIndex}`,attrs:{"placeholder":"Куда","is-valid":true,"value":_vm.fields.endPoint},on:{"change":_vm.setZone,"input":function($event){return _vm.update({
                    field: 'endPoint',
                    value: $event,
                  })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)]),_c('div',{staticClass:"way-block__switcher",attrs:{"role":"button"},on:{"click":function($event){return _vm.switchFields('startPoint', 'endPoint')}}},[_c('esmp-icon',{attrs:{"name":"24-arrow-right-left"}})],1)])]),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('div',{staticClass:"business-trip__expenses-field"},[_c('validation-provider',{attrs:{"name":`Когда ${(_vm.currentIndex + 1)}`,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('esmp-datepicker-adaptive',{attrs:{"placeholder":"Когда","value":_vm.fields.day},on:{"change":function($event){return _vm.update({
                  field: 'day',
                  value: $event,
                })}}}),(errors[0])?_c('div',{staticClass:"business-trip__error"},[_vm._v(" "+_vm._s(errors[0])+" ")]):_vm._e()]}}])})],1)])]),(_vm.fields.transitWay === _vm.plane)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('esmp-input',{attrs:{"error-message":"","label":"Номер рейса","value":_vm.fields.planeTripNumber},on:{"input":function($event){return _vm.update({
              field: 'planeTripNumber',
              value: $event,
            })}}})],1)]):_vm._e(),(_vm.fields.transitWay === _vm.train)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('esmp-input',{attrs:{"error-message":"","label":"Номер поезда","value":_vm.fields.trainTripNumber},on:{"input":function($event){return _vm.update({
              field: 'trainTripNumber',
              value: $event,
            })}}})],1)]):_vm._e(),(_vm.fields.transitWay === _vm.bus)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('esmp-input',{attrs:{"error-message":"","label":"Номер рейса","value":_vm.fields.busTripNumber},on:{"input":function($event){return _vm.update({
              field: 'busTripNumber',
              value: $event,
            })}}})],1)]):_vm._e(),_c('div',{staticClass:"business-trip__expenses-col"},[_c('div',{staticClass:"business-trip__expenses-fieldset"},[_c('esmp-select',{attrs:{"placeholder":"Отправление","filterable":"","clearable":"","disabled":_vm.isHasTripNumber,"value":_vm.fields.timeOfDay ? _vm.fields.timeOfDay.id : null},on:{"input":function($event){return _vm.update({
              field: 'timeOfDay',
              value: $event,
              list: _vm.timeOfDay,
            })}}},_vm._l((_vm.timeOfDay),function(item){return _c('esmp-select-option',{key:item.id,attrs:{"value":item.id}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]),(_vm.isVip && _vm.fields.transitWay === _vm.plane)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('esmp-checkbox',{attrs:{"value":_vm.fields.isBusinessClass},on:{"input":function($event){return _vm.update({
            field: 'isBusinessClass',
            value: $event,
          })}}},[_vm._v(" Бизнес-класс ")])],1):_vm._e(),(_vm.isVip && _vm.fields.transitWay === _vm.train)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('esmp-checkbox',{attrs:{"value":_vm.fields.isFirstClass},on:{"input":function($event){return _vm.update({
            field: 'isFirstClass',
            value: $event,
          })}}},[_vm._v(" Купе 1 класс ")])],1):_vm._e(),(_vm.isAvailableRemove)?_c('div',{staticClass:"business-trip__expenses-col"},[_c('action-btn',{attrs:{"is-remove":true},on:{"click":_vm.removeTransit}},[_vm._v(" Удалить маршрут ")])],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }